import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { OrderType } from "../OrderType";

type Props = {
  order: OrderType;
};

const FinalStep = ({ order }: Props) => {
  const { t }: { t: any } = useTranslation();

  return (
    <Box
      sx={{
        minHeight: "300",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "25px",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          textAlign: "center",
        }}
      >
        {t("THANK_YOU.THANK_YOU_FOR_YOUR_ORDER")}
      </Typography>
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
        }}
      >
        {t("THANK_YOU.ADDITIONAL_ORDER_INFO")}
      </Typography>
      {/* <Typography
        variant="body1"
        sx={{
          marginTop: "10px",
          textAlign: "center",
        }}
      >
        {t("THANK_YOU.ORDER_NO")} {order.opportunityNo}
      </Typography> */}
    </Box>
  );
};

export default FinalStep;
