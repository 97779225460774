import { Alert } from '@mui/material'
import React from 'react'
import { BadRequestData } from './BadRequestData'

type Props = {
    errorData: BadRequestData;
}

const Error400 = ({ errorData }: Props) => {
    return (
        <Alert severity="error">{errorData.error.message}</Alert>
    )
}

export default Error400
