import { Snackbar } from '@mui/material';
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Stack from '@mui/material/Stack';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  hasError: boolean;
  handleClose: () => void;
}

const StyledAlert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const ErrorSnack = ({ hasError, handleClose }: Props) => {
  const { t }: { t: any } = useTranslation();

  return (
    <Stack spacing={2} sx={{ width: "100%" }} position="relative">
      <Snackbar
        style={{ position: 'absolute' }}
        open={hasError}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleClose}
      >
        <StyledAlert
          onClose={handleClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {t("SHARED.ERROR_MESSAGE")}
        </StyledAlert>
      </Snackbar>
    </Stack>
  )
}

export default ErrorSnack