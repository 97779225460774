import { Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { SettingsType } from '../../settings/SettingsType';

type Props = {
    settings: SettingsType[];
    noOfChips: number;
}

const PriceCard = ({ settings, noOfChips }: Props) => {
    const calculateRepairPrice = (rate: number, noOfChipsToRepair: number) => {
        //  = Repair Price ( konstanta, redaguojama ) x ( 1+ ( Number of Chips - 1)/2)
        return rate * (1 + (noOfChips - 1) / 2)
    }

    const priceValue = settings.find(o => o.key === 'repair_price.price_lt')
    const price = calculateRepairPrice(priceValue?.numberValue ?? 0, noOfChips)

    return (
        <Typography variant={"h6"} dangerouslySetInnerHTML={{ __html: t("SERVICE.PRICE_ANNOTATION", { price: price.toFixed(2) }) }} />
    )
}

export default PriceCard;
