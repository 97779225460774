import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  formik: any;
};

const WipersCard = ({ formik }: Props) => {
  const { t }: { t: any } = useTranslation();

  return (
    <Card elevation={0} sx={{ height: "100%" }}>
      <CardHeader
        title={
          <Typography variant={"h6"}>
            {t("WIPERS.YOUR_SAFETY_AT_DRIVING")}
          </Typography>
        }
      />
      <Divider variant="middle" />
      <CardContent>
        <Grid container>
          <Grid xs={12} sm={6} item>
            <Typography variant="body1" sx={{ marginBottom: "15px" }}>
              {t("WIPERS.OFFER_A")} {t("WIPERS.DONT_MISS")}
            </Typography>

            <FormControlLabel
              checked={formik.values.wipers}
              onChange={() => formik.setFieldValue("wipers", !formik.values.wipers)}
              control={<Checkbox />}
              label={t("WIPERS.I_WOULD_LIKE_TO_BUY_WIPERS")}
            />
          </Grid>
          <Grid xs={12} sm={6} item>
            <div style={{ textAlign: 'center' }}>
              <img
                src={`${process.env.REACT_APP_PUBLIC_URL}assets/Bosch-wipersjpg.jpg`}
                alt=""
                className="img-thumbnail"
                width={"250"}
              />
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card >
  );
};

export default WipersCard;
