import { CardHeader, Grid, Typography } from "@mui/material";
import useAxios from "axios-hooks";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import Loading from "../../core/Loading";
import ErrorBox from "../../errors/ErrorBox";
import ErrorSnack from "../../errors/ErrorSnack";
import { InsuranceCompanyType } from "../../InsuranceCompanyType";
import { createOrderSubmitPayload } from "../../orders/createOrderSubmitPayload";
import { ErpError, SubmitOrderResponse } from "../../orders/SubmitOrderResponse";
import { OrderType } from "../../OrderType";
import { Page } from "../../Page";
import { trackEvent } from "../../shared/trackEvent";
import Buttons from "../Buttons";
import ContactDetailsCard from "./ContactDetailsCard";
import DamagesCard from "./DamagesCard";
import InsuranceCard from "./InsuranceCard";

type Props = {
  order: OrderType;
  onNext: any;
};

const Step1 = ({ order, onNext }: Props) => {
  useEffect(() => {
    trackEvent("Booking", "Step 1 Opened")
  }, [])

  const { t }: { t: any } = useTranslation();

  const [{ data: insuranceCompanies, loading: insuranceCompaniesLoading }] =
    useAxios<Page<InsuranceCompanyType>>(
      {
        url: `${process.env.REACT_APP_API_URL}insurance-companies`,
      },
      { useCache: false }
    );

  const [{ loading: orderSubmitting, error: submitError }, submitOrder] =
    useAxios<SubmitOrderResponse>(
      {
        url: `${process.env.REACT_APP_API_URL}booking`,
        method: "POST",
      },
      {
        useCache: false,
        manual: true,
      }
    );

  const validationSchema = yup
    .object({
      damage: yup.object({
        wsDamage: yup.boolean(),
        damageType: yup
          .string()
          .test(
            "crackOrChipSelected",
            t("DAMAGES.PLEASE_SELECT_CRACK_OR_CHIP"),
            function (value) {
              if (this.parent.wsDamage && !value) return false;
              return true;
            }
          ),
        noOfChips: yup
          .mixed()
          .test(
            "noOfChipsSelected",
            t("DAMAGES.PLEASE_SELECT_NO_OF_CHIPS"),
            function (value) {
              if (
                this.parent.wsDamage &&
                this.parent.damageType === "chip" &&
                !value
              )
                return false;
              return true;
            }
          ),
        rwDamage: yup.boolean(),
        otherDamage: yup.boolean(),
        boLeftDamage: yup
          .boolean()
          .test(
            "boLeftDamageSelected",
            t("DAMAGES.PLEASE_SELECT_FRONT_OR_OTHER"),
            function (value) {
              if (value && !this.parent.lfd && !this.parent.lbo) return false;
              return true;
            }
          ),
        boRightDamage: yup
          .boolean()
          .test(
            "boRightDamageSelected",
            t("DAMAGES.PLEASE_SELECT_FRONT_OR_OTHER"),
            function (value) {
              if (value && !this.parent.rfd && !this.parent.rbo) return false;
              return true;
            }
          ),
      }),
      contactDetails: yup.object({
        name: yup.string().required(t("CONTACT_DETAILS.NAME_IS_REQUIRED")),
        email: yup
          .string()
          .email(t("CONTACT_DETAILS.EMAIL_NOT_VALID"))
          .required(t("CONTACT_DETAILS.EMAIL_IS_REQUIRED")),
        phone: yup.string().required(t("CONTACT_DETAILS.PHONE_IS_REQUIRED")),
      }),
      agreeWithTerms: yup.boolean().oneOf([true], t("ACCEPT.MUST_BE_ACCEPTED")),
      insurance: yup.object({
        kasko: yup.boolean(),
        insuranceCompany: yup.object().nullable().test(
          "insuranceCompanyRequired",
          t("INSURANCE.INSURANCE_COMPANY_IS_REQUIRED"),
          function (value) {
            if (this.parent.kasko && !value) return false;
            return true;
          }
        ),
        claimRegistered: yup.boolean(),
        claimNo: yup.string(),
      }),
      attachments: yup.array(),
    })
    .test({
      name: "atLeastOneDamageRequired",
      test: function (values) {
        const isValid =
          values.damage.wsDamage ||
          values.damage.rwDamage ||
          values.damage.otherDamage ||
          values.damage.boLeftDamage ||
          values.damage.boRightDamage ||
          (values.attachments && values.attachments.length > 0);

        if (isValid) return true;

        return this.createError({
          path: "atLeastOneDamageRequired",
          message: t("DAMAGES.DAMAGE_SELECTION_REQUIRED"),
        });
      },
    });

  const [erpError, setErpError] = useState<ErpError>();

  const formik = useFormik<OrderType>({
    initialValues: order,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setErpError(undefined);

      const orderData = await createOrderSubmitPayload(formik.values, 1);
      const response = await submitOrder({
        data: orderData,
      });

      if (response.data.error) {
        trackEvent("Booking", "Step 1 Server Error")
        setErpError(response.data.error);
      } else {
        const data: OrderType = {
          ...order,
          ...formik.values,
          opportunityId: response.data.result.id,
          opportunityNo: response.data.result.number,
        };

        trackEvent("Booking", "Step 1 Submitted")

        onNext(data);

        window.scrollTo({
          top: 0,
          behavior: "auto",
        });
      }
    },
  })

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleNext = () => {
    trackEvent("Booking", "Clicked Next")
    setSnackbarOpen(true);
    formik.handleSubmit();
  };

  return (
    <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
      {insuranceCompaniesLoading && <Loading />}
      <Grid container spacing={2} p={{ xs: 0, md: 2 }}>
        <Grid item xs={12}>
          <CardHeader
            title={
              <Typography
                variant={"h4"}
                sx={{ color: "rgb(211, 47, 47)", marginBottom: "-30px" }}
              >
                {t("MAIN_HEADING")}
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <DamagesCard formik={formik} />
        </Grid>

        <Grid item xs={12} md={6}>
          <ContactDetailsCard formik={formik} />
        </Grid>

        <Grid item xs={12} md={6}>
          <InsuranceCard
            formik={formik}
            insuranceCompanies={insuranceCompanies?.data}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <Buttons
            activeStep={1}
            handleNext={handleNext}
            formik={formik}
            submitting={orderSubmitting}
          />
          <ErrorBox error={submitError} erpError={erpError} />
        </Grid>
      </Grid>

      {!formik.isValid && (
        <ErrorSnack
          hasError={!formik.isValid && snackbarOpen}
          handleClose={() => setSnackbarOpen(false)}
        />
      )}
    </form>
  );
};

export default Step1;
