import ReactGA from 'react-ga';

type Category = "Booking"
type Action =
  "Step 1 Opened"
  | "Step 1 Submitted"
  | "Step 1 Server Error"
  | "Step 2 Opened"
  | "Step 2 Submitted"
  | "Step 2 Server Error"
  | "Clicked Back"
  | "Clicked Next"
  | "Clicked Send"

export const trackEvent = (category: Category, action: Action, label: string | undefined = undefined) => {
  console.log("GA event:", category, ":", action);
  ReactGA.event({
    category: category,
    action: action,
    //   label: label,
  })
}