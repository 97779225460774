import Alert from '@mui/material/Alert';
import React from 'react';

type Props = {
    errorData: string;
}

const Error500 = ({ errorData }: Props) => {
    return (
        <Alert severity="error">{errorData}</Alert>
    )
}

export default Error500
