import { Box, Button, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { OrderType } from "../OrderType";
import FinalStep from "./FinalStep";
import Step1 from "./step1/Step1";
import Step2 from "./step2/Step2";

const steps = [
  {
    id: 1,
    label: "",
  },
  {
    id: 2,
    label: "",
  },
  {
    id: 3,
    label: "",
  },
];

const Booking = () => {
  const [activeStep, setActiveStep] = React.useState(0);

  const [order, setOrder] = useState<OrderType>({
    job: {
      jobSimpleType: "replacement",
      department: null,
      jobDate: "",
      customerStartTime: "",
    },
    opportunityId: false,
    agreeWithTerms: false,
    wipers: false,
    damage: {
      wsDamage: false,
      damageType: "",
      crack: false,
      chip: false,
      rwDamage: false,
      otherDamage: false,
      boLeftDamage: false,
      boRightDamage: false,
      lfd: false,
      lbo: false,
      rfd: false,
      rbo: false,
    },
    contactDetails: {
      name: "",
      email: "",
      phone: "+370 ",
    },
    insurance: {
      kasko: false,
      insuranceCompany: null,
      claimNo: "",
      claimRegistered: false,
    },
    windscreen: {
      rainSensor: false,
      antena: false,
      heating: false,
      camera: false,
      huDisplay: false,
    },
    vehicle: {
      make: null,
      model: null,
      year: null,
      plateNo: "",
      vin: "",
      important: "",
      owner: {
        ownerType: "naturalPerson",
        code: "",
        name: "",
      },
    },
  })

  const handleNext = (modifiedOrder: OrderType) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setOrder(modifiedOrder);
    console.log('handleNext', modifiedOrder)
  }

  const handleBack = (modifiedOrder: OrderType) => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setOrder(modifiedOrder);
    console.log('handleBack', modifiedOrder)
  }

  const handleReset = () => {
    setActiveStep(0);
  }

  const renderStep = (stepNo: number, description: string) => {
    switch (stepNo) {
      case 0:
        return <Step1 order={order} onNext={handleNext} />;
      case 1:
        return <Step2 order={order} onNext={handleNext} onBack={handleBack} />;
      case 2:
        return <FinalStep order={order} />;
      default:
        return <Typography>{description}</Typography>;
    }
  }

  return (
    <Box
      sx={{
        maxWidth: 1200,
        margin: "auto",
        "& .css-957bl1-MuiStepIcon-text": {
          fontWeight: "bold",
          fontSize: "14px",
        },
      }}
      py={4}
    >
      <Paper elevation={3} sx={{ p: 1 }}>
        {/* <Stepper activeStep={activeStep} sx={{ pt: 2 }}>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper> */}
        {activeStep === steps.length ? (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>Užsakymas priimtas</Typography>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Teikti naują užsakymą
            </Button>
          </Paper>
        ) : (
          <React.Fragment>
            <Box sx={{ mt: 2, mb: 1 }}>{renderStep(activeStep, "")}</Box>
          </React.Fragment>
        )}
      </Paper>
    </Box>
  );
};

export default Booking;
