import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { Suspense } from "react";
import "./App.css";
import Booking from "./components/booking/Booking";
import ReactGA from 'react-ga';

if (process.env.REACT_APP_GA_TRACKING_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID)
}

function App() {
  const theme = createTheme({
    typography: {
      body1: {
        fontSize: "16px",
        fontFamily: "Roboto",
        fontWeight: "300",
        lineHeight: "18px",
      },
      body2: {
        fontSize: "16px",
        fontFamily: "Roboto",
        fontWeight: "400",
      },
      caption: {
        fontSize: "16px",
        fontFamily: "Roboto",
        fontWeight: "300",
      },
      h6: {
        fontSize: "24px",
        fontFamily: "Roboto",
        fontWeight: "300",
        lineHeight: "32px",
      },
      h4: {
        fontSize: "32px",
        fontFamily: "Roboto",
        fontWeight: "300",
      },
      h5: {
        fontSize: "18px",
        fontFamily: "Roboto",
        fontWeight: "300",
      },
      button: {
        fontFamily: "Roboto",
        fontWeight: "400",
      },

      // subtitle1: {
      //   fontSize: "12px",
      //   fontFamily: "Roboto",
      //   fontWeight: "400",
      // },
      // subtitle2: {
      //   fontSize: "12px",
      //   fontFamily: "Roboto",
      //   fontWeight: "400",
      // },
      // overline: {
      //   fontSize: "12px",
      //   fontFamily: "Roboto",
      //   fontWeight: "400",
      // },
    },
    palette: {
      primary: {
        main: "#e12731",
      },
    },
  });

  return (
    <React.Fragment>
      <CssBaseline />
      <Suspense fallback="">
        <ThemeProvider theme={theme}>
          <div className="App">
            <Booking />
            {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
          </div>
        </ThemeProvider>
      </Suspense>
    </React.Fragment>
  );
}

export default App;
