import { Alert } from "@mui/material";
import { AxiosError } from "axios";
import React from "react";
import { ErpError } from "../orders/SubmitOrderResponse";
import { ErpErrorBox } from "./ErpErrorBox";
import Error400 from "./Error400";
import Error500 from "./Error500";

type Props = {
  error: AxiosError<any, any> | ErpError | null | undefined;
  erpError: ErpError | undefined;
};

const ErrorBox = ({ error, erpError }: Props) => {
  if (erpError) return <ErpErrorBox error={erpError} />;

  if (!error) return null;

  const axiosError = error as AxiosError<any, any>;

  if (axiosError?.response?.status === 400)
    return <Error400 errorData={axiosError.response.data} />;

  if (axiosError?.response?.status === 500)
    return <Error500 errorData={axiosError.response.data} />;

  return (
    <div>
      {error?.message && <Alert severity="error">{error.message}</Alert>}
    </div>
  );
};

export default ErrorBox;
