import { LoadingButton } from "@mui/lab";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Typography
} from "@mui/material";
import Box from "@mui/material/Box/Box";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  activeStep: number;
  handleBack?: any;
  handleNext: any;
  formik?: any;
  submitting: boolean;
};

const Buttons = ({
  activeStep,
  handleBack,
  handleNext,
  formik,
  submitting,
}: Props) => {
  const { t }: { t: any } = useTranslation();

  return (
    <>
      {formik && activeStep === 1 && (
        <FormControl
          required
          error={
            formik.touched.agreeWithTerms &&
            Boolean(formik.errors.agreeWithTerms)
          }
          component="fieldset"
          sx={{ mx: 2, padding: 0 }}
          variant="standard"
        >
          <FormGroup style={{ display: "inline" }}>
            <FormControlLabel
              checked={formik.values.agreeWithTerms}
              onChange={() =>
                formik.setFieldValue(
                  "agreeWithTerms",
                  !formik.values.agreeWithTerms
                )
              }
              control={<Checkbox />}
              label=""
              style={{ marginRight: 0 }}
            />
            <Typography
              component="span"
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: t("ACCEPT.AGREE_WITH_TERMS"),
              }}
            ></Typography>
          </FormGroup>
          <FormHelperText>
            {formik.touched.agreeWithTerms && formik.errors.agreeWithTerms}
          </FormHelperText>
        </FormControl>
      )}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          pt: 2,
          mx: 2,
        }}
      >
        {activeStep > 1 && (
          <Button
            color="inherit"
            disabled={activeStep === 1}
            onClick={handleBack}
            variant="outlined"
            size="large"
            sx={{ mr: 1 }}
          >
            {t("SHARED.RETURN")}
          </Button>
        )}

        <Box sx={{ flex: "1 1 auto" }} />

        <LoadingButton
          loading={submitting}
          color="primary"
          variant="contained"
          onClick={handleNext}
          size="large"
        >
          {activeStep === 2 ? t("SHARED.SEND") : t("SHARED.CONTINUE")}
        </LoadingButton>
      </Box>
    </>
  );
};

export default Buttons;
