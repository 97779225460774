import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';

const Loading = () => {
    return (
        <div style={{
            height: '0',
            zIndex: 999999,
            position: "relative",
        }}>
            <LinearProgress />
        </div>
    )
};

export default Loading;
