import convertBlobToBase64 from "../../services/convertBlobToBase64";
import { OrderType } from "../OrderType";
import { formatPhone } from "../shared/formatPhone";

export async function createOrderSubmitPayload(order: OrderType, step: number) {
  const orderAttachments = order.attachments ? await Promise.all(order.attachments.map(async (file) => {
    return await convertBlobToBase64(file)
  })) : []

  const vehicleAttachments = order.vehicle.attachments ? await Promise.all(order.vehicle.attachments.map(async (file) => {
    return await convertBlobToBase64(file)
  })) : []

  const damaged: any[] = []

  if (order.damage.wsDamage)
    damaged.push('Priekinis stiklas')

  if (order.damage.rwDamage)
    damaged.push('Galinis stiklas')

  if (order.damage.boLeftDamage || order.damage.boLeftDamage)
    damaged.push('Kairės pusės stiklas')

  if (order.damage.boRightDamage || order.damage.boRightDamage)
    damaged.push('Dešinės pusės stiklas')

  if (order.damage.otherDamage)
    damaged.push('Kitas stikas')

  const data = {
    params: {
      step: step,
      web_job_type: order.damage.damageType === 'chip' ? 'repair' : 'replacement',
      damaged: damaged,
      opportunity_id: order.opportunityId, //order.opportunityId,    //# integer (Odoo opportunity ID or False)
      ws_damage: order.damage.wsDamage,      //# boolean (True, False or '')
      crack: order.damage.wsDamage ? order.damage.damageType === 'crack' : false,         //# boolean (True, False or '')
      chip: order.damage.wsDamage ? order.damage.damageType === 'chip' : false,         // # boolean (True, False or '')
      chips_amount: order.damage.wsDamage && order.damage.damageType === 'chip' ? order.damage.noOfChips : false,      //# integer (0, 1, 2, 3...)
      rw_damage: order.damage.rwDamage,     //# boolean (True, False or '')
      lfd: order.damage.boLeftDamage ? order.damage.lfd : false,          // # boolean (True, False or '')
      lbo: order.damage.boLeftDamage ? order.damage.lbo : false,          // # boolean (True, False or '')
      rfd: order.damage.boRightDamage ? order.damage.rfd : false,          // # boolean (True, False or '')
      rbo: order.damage.boRightDamage ? order.damage.rbo : false,           //# boolean (True, False or '')
      other_glass: order.damage.otherDamage,
      customer_company_code: order.vehicle?.owner?.code,   // # string (company code).
      company_name: order.vehicle?.owner?.name,
      contact_name: order.contactDetails.name,         //# string.
      phone: order.contactDetails.phone ? formatPhone(order.contactDetails.phone) : "",          //# string.
      email: order.contactDetails.email,     //# string.
      department_id: order.job?.department?.departmentId,                        // # integer (Odoo department ID or empty string or boolean False).
      department: order.job?.department?.name ?? '',
      web_chosen_date: order.job?.jobDate === '' ? '2022-01-01' : order.job?.jobDate,         //  # string.
      web_chosen_time: order.job?.customerStartTime === '' ? '00:00' : order.job?.customerStartTime,       //  # string.
      car_brand: order.vehicle.make?.make,           // # string (Odoo searches by brand name).
      car_model: order.vehicle.model?.model,            //  # string (Odoo searches id by model name).
      car_year: order.vehicle.year,           //  # string
      plate_no: order.vehicle.plateNo ? order.vehicle.plateNo.toUpperCase().replaceAll(" ", "") : "",          // # string
      vin: order.vehicle.vin ? order.vehicle.vin.replaceAll(" ", "") : "",  // # string
      rain_sensor: order.windscreen.rainSensor,           // # boolean (True, False or '')
      antenna: order.windscreen.antena,                //# boolean (True, False or '')
      heating: order.windscreen.heating,                //# boolean (True, False or '')
      camera: order.windscreen.camera,                 //# boolean (True, False or '')
      hu_display: order.windscreen.huDisplay,             //# boolean (True, False or '') Head-up
      insurance_company_id: order.insurance.kasko ? order.insurance.insuranceCompany?.insuranceCompanyId ?? false : false, //# integer (Odoo ID or False or empty string '').
      claim_registered: order.insurance.kasko ? order.insurance.claimRegistered : false,      //# boolean (True, False or '')
      kasko: order.insurance.kasko,                  //# boolean (True, False or '')
      claim_number: order.insurance.kasko && order.insurance.claimRegistered ? order.insurance.claimNo : false,    //# string or empty string or boolean False,
      internal_notes: `${order.vehicle.important} ${order.vehicle?.owner?.name}`, //"Some descriptions goes here.",    //# string
      channel: 'E BOL',    //# integer (Odoo ID or empty string or boolean False).
      wipers: order.wipers,     //# string (available options: 'yes', 'no' or '').
      //web_cession: '',   //# string (session information or empty string or False.)
      ip: order.ip ?? false,  //# string (ip address or empty string or False)
      //imei: '123456789',    //# string (any string or empty string or boolean False)
      //web_referrer: "https://www.google.lt",   // # string.
      //web_landing: "https://www.instante.lt",   //# string.
      attachments: [...orderAttachments, ...vehicleAttachments]
    }
  }

  return data
}