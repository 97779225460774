import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import AttachImage from "../../attach-image/AttachImage";

type Props = {
  formik: any;
};

const DamagesCard = ({ formik }: Props) => {
  const { t }: { t: any } = useTranslation();

  return (
    <Card
      elevation={0}
      sx={{
        height: "100%",
        "& .css-57jpp3-MuiFormHelperText-root.Mui-error": {
          fontWeight: "normal",
          fontSize: "14px",
        },
      }}
    >
      <CardHeader
        title={<>
          <Typography variant={"h6"}>
            {t("DAMAGES.SELECT_DAMAGED_WINDOW")}
          </Typography>
          <Typography variant={"body1"} marginTop={1}>
            {t("DAMAGES.SELECT_DAMAGED_WINDOW_SUBHEADING")}
          </Typography>
        </>
        }

      />

      <Divider variant="middle" />

      <CardContent>
        <Grid container pb={2} spacing={2}>
          <Grid item xs={12} md={12}>
            <FormGroup>
              <FormControl
                sx={{ marginBottom: "10px", marginLeft: "-11px" }}
                error={Boolean(formik.errors.atLeastOneDamageRequired)}
              >
                <FormHelperText>
                  {formik.errors.atLeastOneDamageRequired}
                </FormHelperText>
              </FormControl>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <FormControl
                    error={
                      formik.touched.damage?.wsDamage &&
                      Boolean(formik.errors.damage?.wsDamage)
                    }
                  >
                    <FormGroup>
                      <img
                        src={`${process.env.REACT_APP_PUBLIC_URL}assets/damage1_windscreen_lhd.jpg`}
                        alt=""
                        className="img-thumbnail"
                        width={"120"}
                      />
                      <FormControlLabel
                        checked={formik.values.damage?.wsDamage}
                        onChange={() =>
                          formik.setFieldValue(
                            "damage.wsDamage",
                            !formik.values.damage?.wsDamage
                          )
                        }
                        control={<Checkbox />}
                        label={
                          <Typography>{t("DAMAGES.WS_DAMAGE")}</Typography>
                        }
                      />
                    </FormGroup>
                    <FormHelperText>
                      {formik.touched.damage?.wsDamage &&
                        formik.errors.damage?.wsDamage}
                    </FormHelperText>
                  </FormControl>

                  {formik.values.damage.wsDamage && (
                    <Paper
                      variant="outlined"
                      square
                      sx={{ padding: 2, marginBottom: "20px" }}
                    >
                      <FormControl
                        error={
                          formik.touched.damage?.damageType &&
                          Boolean(formik.errors.damage?.damageType)
                        }
                      >
                        <RadioGroup
                          aria-label="Damage type"
                          name="damageType"
                          value={formik.values.damage.damageType}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "damage.damageType",
                              e.target.value
                            )
                          }
                        >
                          <FormHelperText>
                            {formik.touched.damage?.damageType &&
                              formik.errors.damage?.damageType}
                          </FormHelperText>
                          <img
                            src={`${process.env.REACT_APP_PUBLIC_URL}assets/damage2_smallerThan_lt.jpg`}
                            alt=""
                            className="img-thumbnail"
                            width={80}
                          />
                          <FormControlLabel
                            value="chip"
                            control={<Radio />}
                            label={t("DAMAGES.CHIP")}
                          />
                          {formik.values.damage.damageType === "chip" && (
                            <FormControl
                              error={Boolean(formik.errors.damage?.noOfChips)}
                            >
                              <Typography sx={{ ml: 1, mt: 1 }} variant="body2">
                                {t("DAMAGES.NO_OF_CHIPS_LABEL")}:
                              </Typography>
                              <ToggleButtonGroup
                                sx={{ ml: 1, mt: 1 }}
                                value={formik.values.damage.noOfChips}
                                exclusive
                                size="small"
                                onChange={(
                                  event: React.MouseEvent<HTMLElement>,
                                  newValue: number
                                ) =>
                                  formik.setFieldValue(
                                    "damage.noOfChips",
                                    newValue
                                  )
                                }
                                aria-label="text alignment"
                              >
                                <ToggleButton
                                  value={1}
                                  aria-label="1"
                                  sx={{
                                    minWidth: 50,
                                  }}
                                >
                                  <Typography variant={"h5"}>1</Typography>
                                </ToggleButton>
                                <ToggleButton
                                  value={2}
                                  aria-label="2"
                                  sx={{
                                    minWidth: 50,
                                  }}
                                >
                                  <Typography variant={"h5"}>2</Typography>
                                </ToggleButton>
                                <ToggleButton
                                  value={3}
                                  aria-label="3"
                                  sx={{
                                    minWidth: 50,
                                  }}
                                >
                                  <Typography variant={"h5"}>3</Typography>
                                </ToggleButton>
                              </ToggleButtonGroup>
                              <FormHelperText>
                                {formik.errors.damage?.noOfChips}
                              </FormHelperText>
                            </FormControl>
                          )}
                          <img
                            src={`${process.env.REACT_APP_PUBLIC_URL}assets/damage2_largerThan_lt.jpg`}
                            alt=""
                            className="img-thumbnail"
                            width={80}
                            style={{ marginTop: "15px" }}
                          />
                          <FormControlLabel
                            value="crack"
                            control={<Radio />}
                            label={t("DAMAGES.CRACK")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Paper>
                  )}
                </Grid>

                <Grid item xs={12} md={4}>
                  <FormControl
                    error={
                      formik.touched.damage?.boLeftDamage &&
                      Boolean(formik.errors.damage?.boLeftDamage)
                    }
                  >
                    <img
                      src={`${process.env.REACT_APP_PUBLIC_URL}assets/damage1_sideWindow.jpg`}
                      alt=""
                      className="img-thumbnail"
                      width={120}
                    />
                    <FormControlLabel
                      checked={formik.values.damage.boLeftDamage}
                      onChange={() =>
                        formik.setFieldValue(
                          "damage.boLeftDamage",
                          !formik.values.damage.boLeftDamage
                        )
                      }
                      control={<Checkbox />}
                      label={
                        <Typography>{t("DAMAGES.BO_LEFT_DAMAGE")}</Typography>
                      }
                    />
                    {formik.values.damage.boLeftDamage && (
                      <Paper variant="outlined" square sx={{ padding: 2 }}>
                        <FormHelperText>
                          {formik.touched.damage?.boLeftDamage &&
                            formik.errors.damage?.boLeftDamage}
                        </FormHelperText>

                        <FormControlLabel
                          checked={formik.values.damage.lfd}
                          onChange={() =>
                            formik.setFieldValue(
                              "damage.lfd",
                              !formik.values.damage.lfd
                            )
                          }
                          control={<Checkbox />}
                          label={t("DAMAGES.LFD")}
                        />

                        <FormControlLabel
                          checked={formik.values.damage.lbo}
                          onChange={() =>
                            formik.setFieldValue(
                              "damage.lbo",
                              !formik.values.damage.lbo
                            )
                          }
                          control={<Checkbox />}
                          label={t("DAMAGES.LBO")}
                        />
                      </Paper>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl
                    error={
                      formik.touched.damage?.boRightDamage &&
                      Boolean(formik.errors.damage?.boRightDamage)
                    }
                  >
                    <img
                      src={`${process.env.REACT_APP_PUBLIC_URL}assets/damage1_sideWindow.jpg`}
                      alt=""
                      className="img-thumbnail"
                      width={120}
                      style={{
                        transform: "scaleX(-1)",
                        WebkitTransform: "scaleX(-1)",
                      }}
                    />

                    <FormControlLabel
                      checked={formik.values.damage.boRightDamage}
                      onChange={() =>
                        formik.setFieldValue(
                          "damage.boRightDamage",
                          !formik.values.damage.boRightDamage
                        )
                      }
                      control={<Checkbox />}
                      label={
                        <Typography>{t("DAMAGES.BO_RIGHT_DAMAGE")}</Typography>
                      }
                    />
                    {formik.values.damage.boRightDamage && (
                      <Paper variant="outlined" square sx={{ padding: 2 }}>
                        <FormHelperText>
                          {formik.touched.damage?.boRightDamage &&
                            formik.errors.damage?.boRightDamage}
                        </FormHelperText>
                        <FormControlLabel
                          checked={formik.values.damage.rfd}
                          onChange={() =>
                            formik.setFieldValue(
                              "damage.rfd",
                              !formik.values.damage.rfd
                            )
                          }
                          control={<Checkbox />}
                          label={t("DAMAGES.RFD")}
                        />
                        <FormControlLabel
                          checked={formik.values.damage.rbo}
                          onChange={() =>
                            formik.setFieldValue(
                              "damage.rbo",
                              !formik.values.damage.rbo
                            )
                          }
                          control={<Checkbox />}
                          label={t("DAMAGES.RBO")}
                        />
                      </Paper>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <img
                    src={`${process.env.REACT_APP_PUBLIC_URL}assets/damage1_rearWindow.jpg`}
                    alt=""
                    className="img-thumbnail"
                    width={120}
                  />
                  <br />
                  <FormControlLabel
                    checked={formik.values.damage.rwDamage}
                    onChange={() =>
                      formik.setFieldValue(
                        "damage.rwDamage",
                        !formik.values.damage.rwDamage
                      )
                    }
                    control={<Checkbox />}
                    label={<Typography>{t("DAMAGES.RW_DAMAGE")}</Typography>}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <img
                    src={`${process.env.REACT_APP_PUBLIC_URL}assets/notSure.jpg`}
                    alt=""
                    className="img-thumbnail"
                    width={120}
                  />
                  <br />
                  <FormControlLabel
                    checked={formik.values.damage.otherDamage}
                    onChange={() =>
                      formik.setFieldValue(
                        "damage.otherDamage",
                        !formik.values.damage.otherDamage
                      )
                    }
                    control={<Checkbox />}
                    label={<Typography>{t("DAMAGES.OTHER_DAMAGE")}</Typography>}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <AttachImage
                    formik={formik}
                    field={"attachments"}
                    message={t("DAMAGES.ATTACH_DAMAGE_PHOTOS")}
                    coverDropzone={false}
                  />
                </Grid>
              </Grid>
            </FormGroup>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DamagesCard;
