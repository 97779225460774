import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { InsuranceCompanyType } from "../../InsuranceCompanyType";

type Props = {
  formik: any;
  insuranceCompanies?: InsuranceCompanyType[];
};

const InsuranceCard = ({ formik, insuranceCompanies }: Props) => {
  const { t }: { t: any } = useTranslation();

  return (
    <Card
      elevation={0}
      sx={{
        height: "100%",
        "& .css-57jpp3-MuiFormHelperText-root.Mui-error": {
          fontWeight: "normal",
          fontSize: "14px",
        },
      }}
    >
      <CardHeader
        title={<Typography variant={"h6"}>{t("INSURANCE.HEADING")}</Typography>}
      />
      <Divider variant="middle" />
      <CardContent>
        <RadioGroup
          aria-label="Insurance"
          name="insurance"
          value={formik.values.insurance.kasko}
          defaultValue="false"
          onChange={(e) =>
            formik.setFieldValue(
              "insurance.kasko",
              !formik.values.insurance.kasko
            )
          }
        >
          <FormControlLabel
            value="false"
            control={<Radio />}
            label={t("INSURANCE.NOINSURANCE")}
          />
          <FormControlLabel
            value="true"
            control={<Radio />}
            label={t("INSURANCE.KASKO")}
          />
          {formik.values.insurance.kasko && (
            <div>
              <Autocomplete
                autoHighlight
                disablePortal
                id="insurance.insuranceCompany"
                options={insuranceCompanies ?? []}
                getOptionLabel={(option) => option.shortName}
                noOptionsText={t("SHARED.NO_OPTIONS")}
                renderInput={(params) => (
                  <TextField
                    autoComplete="off"
                    {...params}
                    required
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off",
                    }}
                    label={t("INSURANCE.INSURANCE_COMPANY")}
                    error={
                      formik.touched.insurance?.insuranceCompany &&
                      Boolean(formik.errors.insurance?.insuranceCompany)
                    }
                    helperText={
                      formik.touched.insurance?.insuranceCompany &&
                      formik.errors.insurance?.insuranceCompany
                    }
                  />
                )}
                value={formik.values.insurance.insuranceCompany}
                onChange={(e, v) =>
                  formik.setFieldValue("insurance.insuranceCompany", v)
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
              <FormControlLabel
                checked={formik.values.insurance.claimRegistered}
                onChange={() =>
                  formik.setFieldValue(
                    "insurance.claimRegistered",
                    !formik.values.insurance.claimRegistered
                  )
                }
                control={<Checkbox />}
                label={t("INSURANCE.CLAIM_REGISTERED")}
              />
              {formik.values.insurance.claimRegistered && (
                <TextField
                  autoComplete="off"
                  fullWidth
                  id="insurance.claimNo"
                  name="insurance.claimNo"
                  label={t("INSURANCE.CLAIM_NO")}
                  value={formik.values.insurance.claimNo.replaceAll(" ", "")}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.insurance?.claimNo &&
                    Boolean(formik.errors.insurance?.claimNo)
                  }
                  helperText={
                    formik.touched.insurance?.claimNo &&
                    formik.errors.insurance?.claimNo
                  }
                  InputProps={{
                    endAdornment: (
                      <Tooltip title={t("INSURANCE.CLAIM_NO_NOTE")}>
                        <InfoOutlinedIcon sx={{ fill: "grey" }} />
                      </Tooltip>
                    ),
                  }}
                />
              )}
            </div>
          )}
        </RadioGroup>
      </CardContent>
    </Card>
  );
};

export default InsuranceCard;
