import { Alert } from '@mui/material';
import React from 'react';
import { ErpError } from '../orders/SubmitOrderResponse';

type Props = {
    error: ErpError;
}

export const ErpErrorBox = ({ error }: Props) => {
    return (
        <div>
            {error?.message &&
                <Alert severity="error">
                    <div>{error.message}</div>
                    <div>{error.data?.message}</div>
                </Alert>}
        </div>
    )
};
