import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Grid, IconButton } from "@mui/material";
import React, { CSSProperties, useState } from "react";
import { useDropzone } from "react-dropzone";
import "react-dropzone/examples/theme.css";

const thumb: CSSProperties = {
  display: "flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  paddingBottom: "100%",
  flexDirection: "column",
  position: "relative",
};

const thumbImage: CSSProperties = {
  height: "100%",
  width: "100%",
  position: "absolute",
  objectFit: "cover",
};

const thumbButton: CSSProperties = {
  position: "absolute",
  alignSelf: "end",
  color: "#e12731",
};

type Props = {
  formik: any;
  field: string;
  message: string;
  coverDropzone: boolean;
};

const AttachImage = ({ formik, field, message, coverDropzone }: Props) => {
  const [files, setFiles] = useState<File[]>(getDeepProperty(formik.values, field) ?? []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      const blobs = acceptedFiles
        .filter(
          (file) =>
            files.find((exFile) => exFile.name === file.name) === undefined
        )
        .map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );

      const allFiles = [...files, ...blobs]

      formik.setFieldValue(field, allFiles);

      setFiles(allFiles);
    },
  });

  const handleRemove = (file: File) => {
    const newFiles = [...files];
    const index = files.indexOf(file);
    if (index > -1) {
      newFiles.splice(index, 1);
    }

    formik.setFieldValue(field, newFiles);

    setFiles(newFiles);
  };

  const thumbs = files.map((file: any) => (
    <Grid item key={file.name} xs={6} sm={3} md={6}>
      <div style={thumb}>
        <img style={thumbImage} src={file.preview} alt="" />
        <IconButton onClick={() => handleRemove(file)} style={thumbButton}>
          <DeleteForeverIcon />
        </IconButton>
      </div>
    </Grid>
  ));

  return (
    <>
      {coverDropzone && (
        <>
          {files.length === 0 && (
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <AddAPhotoIcon fontSize="large"></AddAPhotoIcon>
              <p
                dangerouslySetInnerHTML={{ __html: message }}
                style={{ textAlign: "center" }}
              ></p>
            </div>
          )}
        </>
      )}
      {!coverDropzone && (
        <>
          <div
            {...getRootProps({ className: "dropzone" })}
            style={{ marginBottom: "15px" }}
          >
            <input {...getInputProps()} />
            <AddAPhotoIcon fontSize="large"></AddAPhotoIcon>
            <p
              dangerouslySetInnerHTML={{ __html: message }}
              style={{ textAlign: "center" }}
            ></p>
          </div>
        </>
      )}
      <Grid container spacing={2}>
        {thumbs}
      </Grid>
    </>
  );
};

export default AttachImage;

function getDeepProperty<T, K extends keyof T>(o: any, propertyName: string): T[K] {
  const value = propertyName.split('.').reduce(function (p, prop) { return p ? p[prop] : '' }, o);
  return value;
}
