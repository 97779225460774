import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormGroup,
  Grid,
  TextField, Tooltip,
  Typography
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatPhone } from "../../shared/formatPhone";

type Props = {
  formik: any;
}

const ContactDetailsCard = ({ formik }: Props) => {
  const { t }: { t: any } = useTranslation();

  return (
    <Card
      elevation={0}
      sx={{
        height: "100%",
        "& .css-57jpp3-MuiFormHelperText-root.Mui-error": {
          fontWeight: "normal",
          fontSize: "14px",
        },
      }}
    >
      <CardHeader
        title={
          <Typography variant={"h6"}>{t("CONTACT_DETAILS.HEADING")}</Typography>
        }
      />
      <Divider variant="middle" />
      <CardContent>
        <FormGroup>
          <Grid container spacing={2}>
            <Grid item xs={12}>

              <TextField
                autoComplete="off"
                required
                fullWidth
                id="contactDetails.name"
                name="contactDetails.name"
                label={t("CONTACT_DETAILS.NAME")}
                value={formik.values.contactDetails.name?.replace(/[0-9-=.([{}\]|,;/']/g, '')}
                onChange={formik.handleChange}
                error={
                  formik.touched.contactDetails?.name &&
                  Boolean(formik.errors.contactDetails?.name)
                }
                helperText={
                  formik.touched.contactDetails?.name &&
                  formik.errors.contactDetails?.name
                }
                InputProps={{
                  endAdornment: (
                    <Tooltip title={t("CONTACT_DETAILS.NAME_NOTE")}>
                      <InfoOutlinedIcon sx={{ fill: "grey" }} />
                    </Tooltip>
                  ),
                }}
              />

            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                fullWidth
                required
                id="contactDetails.phone"
                name="contactDetails.phone"
                label={t("CONTACT_DETAILS.PHONE")}
                value={formatPhone(formik.values.contactDetails.phone)}
                onChange={formik.handleChange}
                error={
                  formik.touched.contactDetails?.phone &&
                  Boolean(formik.errors.contactDetails?.phone)
                }
                helperText={
                  formik.touched.contactDetails?.phone &&
                  formik.errors.contactDetails?.phone
                }
                InputProps={{
                  endAdornment: (
                    <Tooltip title={t("CONTACT_DETAILS.PHONE_NOTE")}>
                      <InfoOutlinedIcon sx={{ fill: "grey" }} />
                    </Tooltip>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                fullWidth
                required
                id="contactDetails.email"
                name="contactDetails.email"
                label={t("CONTACT_DETAILS.EMAIL")}
                value={formik.values.contactDetails.email.replaceAll(" ", "")}
                onChange={formik.handleChange}
                error={
                  formik.touched.contactDetails?.email &&
                  Boolean(formik.errors.contactDetails?.email)
                }
                helperText={
                  formik.touched.contactDetails?.email &&
                  formik.errors.contactDetails?.email
                }
                InputProps={{
                  endAdornment: (
                    <Tooltip title={t("CONTACT_DETAILS.EMAIL_NOTE")}>
                      <InfoOutlinedIcon sx={{ fill: "grey" }} />
                    </Tooltip>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </FormGroup>
      </CardContent>
    </Card>
  );
};

export default ContactDetailsCard

