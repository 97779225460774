import { AttachmentType } from "../components/AttachmentType";

const convertBlobToBase64 = async (blob: File) => {
  return await blobToBase64(blob);
}

const blobToBase64 = (blob: File) => new Promise<AttachmentType>((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onload = () => resolve({
    data: (reader.result as string)?.split(',')[1],
    name: blob.name
  });
  reader.onerror = error => reject(error);
});

export default convertBlobToBase64