import { Divider, ToggleButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type Props = {
  date: string;
  onSelectDate: (date: string) => void;
  isSelected: boolean;
  timeSlotCount: number | undefined;
};

const DateButton = ({
  date,
  onSelectDate,
  isSelected,
  timeSlotCount,
}: Props) => {
  const title = date.slice(date.length - 5);
  const { t }: { t: any } = useTranslation();

  const checkTimeSlotLabel = (timeSlotCount: number | undefined) => {
    return timeSlotCount === 1
      ? t("TIME_PICKER.TIME_SLOT")
      : timeSlotCount === 10 ||
        timeSlotCount === 11 ||
        timeSlotCount === 0 ||
        timeSlotCount === 12 ||
        timeSlotCount === 13
      ? t("TIME_PICKER.TIME_SLOTS_MANY")
      : t("TIME_PICKER.TIME_SLOTS");
  };
  function getDayOfWeek(date: any) {
    const dayOfWeek = new Date(date).getDay();
    return isNaN(dayOfWeek)
      ? null
      : [
          `${t("WEEKDAYS.SUNDAY")}`,
          `${t("WEEKDAYS.MONDAY")}`,
          `${t("WEEKDAYS.TUESDAY")}`,
          `${t("WEEKDAYS.WEDNESDAY")}`,
          `${t("WEEKDAYS.THURSDAY")}`,
          `${t("WEEKDAYS.FRIDAY")}`,
          `${t("WEEKDAYS.SATURDAY")}`,
        ][dayOfWeek];
  }

  return (
    <ToggleButton
      fullWidth
      value="check"
      color="primary"
      selected={isSelected}
      onChange={() => {
        onSelectDate(date);
      }}
      style={{ position: "relative", display: "flex", flexDirection: "column" }}
    >
      <div>
        <h5
          style={{
            marginBottom: "5px",
            marginTop: 0,
            textTransform: "capitalize",
          }}
        >
          {getDayOfWeek(date)}
        </h5>
        <h5 style={{ marginBottom: "5px", marginTop: 0 }}>{title}</h5>
        <Divider
          variant="middle"
          sx={{
            marginLeft: "5",
            marginRight: "5",
            padding: 0,
            width: "30px",
            minWidth: "30px",
            textAlign: "center",
          }}
        ></Divider>
        <h5 style={{ padding: 0, margin: 5 }}>{timeSlotCount}</h5>
        <Typography
          component="p"
          sx={{
            fontSize: "10px",
            padding: 0,
            marginTop: "-5px",
            marginBottom: 0,
            fontWeight: "bolder",
          }}
        >
          {checkTimeSlotLabel(timeSlotCount)}
        </Typography>
      </div>
    </ToggleButton>
  );
};

export default DateButton;
