import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Button,
  FormControl,
  FormHelperText,
  Theme,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { addDays } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import { BookingTimesType } from "../booking-times/BookingTimesType";
import DateButton from "./DateButton";
import TimeButton from "./TimeButton";

type Props = {
  bookingTimes: BookingTimesType;
  date?: string;
  time?: string;
  onSelectDate: (date: string) => void;
  onSelectTime: (date: string) => void;
  currentDate: Date;
  onCurrentDateChange: (date: Date) => void;
  formik: any;
};

const TimeSelector = ({
  bookingTimes,
  date,
  time,
  onSelectDate,
  onSelectTime,
  currentDate,
  onCurrentDateChange,
  formik,
}: Props) => {
  const { t }: { t: any } = useTranslation();

  const renderTimes = (times: string[]) => {
    return times?.map((timeSlot) => {
      return (
        <Grid key={timeSlot} item xs={12}>
          {times.length !== 0 && (
            <TimeButton
              time={timeSlot}
              onSelectTime={onSelectTime}
              isSelected={timeSlot === time}
            />
          )}
        </Grid>
      );
    });
  };

  const handleRight = () => {
    onCurrentDateChange(addDays(currentDate, matches ? 4 : 7));
  };

  const handleLeft = () => {
    const startDate = addDays(currentDate, matches ? -4 : -7);
    const today = new Date();
    onCurrentDateChange(startDate < today ? today : startDate);
  };

  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const renderDays = Object.keys(bookingTimes.result)
    .slice(0, matches ? 4 : 7)
    .map((dateSlot) => {
      return (
        <Grid key={dateSlot} item sm={1.5} xs={2.5}>
          <DateButton
            date={dateSlot}
            onSelectDate={onSelectDate}
            isSelected={dateSlot === date}
            timeSlotCount={bookingTimes.result[dateSlot].length}
          />
        </Grid>
      );
    });

  return (
    <>
      <Grid container spacing={0.5} sx={{ mb: 2 }}>
        {!matches && <Grid item xs={0.75}></Grid>}
        <Grid item xs={12} sm={10.5}>
          <FormControl
            error={
              formik.touched.job?.jobDate && Boolean(formik.errors.job?.jobDate)
            }
          >
            <FormHelperText>
              {formik.touched.job?.jobDate && formik.errors.job?.jobDate}
            </FormHelperText>
          </FormControl>
        </Grid>
        {!matches && <Grid item xs={0.75}></Grid>}
        <Grid item xs={1} sm={0.75} style={{ display: "flex" }}>
          <Button sx={{ minWidth: "100%" }} onClick={handleLeft}>
            <KeyboardArrowLeftIcon />
          </Button>
        </Grid>
        {renderDays}
        <Grid item xs={1} sm={0.75} style={{ display: "flex" }}>
          <Button sx={{ minWidth: "100%" }} onClick={handleRight}>
            <KeyboardArrowRightIcon />
          </Button>
        </Grid>
        <Grid item xs={0.75}></Grid>
        <Grid item xs={10.5} sx={{ mt: 2 }}>
          <FormControl
            error={
              formik.touched.job?.customerStartTime &&
              Boolean(formik.errors.job?.customerStartTime)
            }
          >
            <FormHelperText>
              {formik.touched.job?.customerStartTime &&
                formik.errors.job?.customerStartTime}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={0.75}></Grid>
        <Grid item xs={0.75}></Grid>
        <Grid item xs={10.5}>
          {renderTimes(bookingTimes.result[date ?? ""])}
          {bookingTimes?.result[date ?? ""]?.length === 0 && (
            <h4 style={{ color: "#e12731", textAlign: "center" }}>
              {t("TIME_PICKER.NO_TIME_SLOTS")}
            </h4>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default TimeSelector;
