import { ToggleButton } from "@mui/material";
import React from "react";

type Props = {
  time: string;
  onSelectTime: (date: string) => void;
  isSelected: boolean;
};

const TimeButton = ({ time, onSelectTime, isSelected }: Props) => {
  return (
    <ToggleButton
      sx={{ height: "50px", mb: 1 }}
      fullWidth
      value="check"
      color="primary"
      selected={isSelected}
      onChange={() => {
        onSelectTime(time);
      }}
    >
      <h4>{time}</h4>
    </ToggleButton>
  );
};

export default TimeButton;
