import {
  Card,
  CardHeader,
  Typography,
  CardContent,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
} from "@mui/material";
import { useTranslation } from "react-i18next";

type Props = {
  formik: any;
};

const WindScreenCard = ({ formik }: Props) => {
  const { t }: { t: any } = useTranslation();

  return (
    <Card
      elevation={0}
      sx={{
        height: "100%",
        "& .css-57jpp3-MuiFormHelperText-root.Mui-error": {
          fontWeight: "normal",
          fontSize: "12px",
        },
      }}
    >
      <CardHeader
        title={
          <Typography variant={"h6"}>
            {t("WINDSCREEN.WINDSCREEN_SET")}
          </Typography>
        }
      />
      <Divider variant="middle" />
      <CardContent>
        <FormGroup>
          <FormControlLabel
            checked={formik.values.windscreen.rainSensor}
            onChange={() =>
              formik.setFieldValue(
                "windscreen.rainSensor",
                !formik.values.windscreen.rainSensor
              )
            }
            control={<Checkbox />}
            label={t("WINDSCREEN.RAIN_SENSOR")}
          />
          <FormControlLabel
            checked={formik.values.windscreen.antena}
            onChange={() =>
              formik.setFieldValue(
                "windscreen.antena",
                !formik.values.windscreen.antena
              )
            }
            control={<Checkbox />}
            label={t("WINDSCREEN.ANTENA")}
          />
          <FormControlLabel
            checked={formik.values.windscreen.heating}
            onChange={() =>
              formik.setFieldValue(
                "windscreen.heating",
                !formik.values.windscreen.heating
              )
            }
            control={<Checkbox />}
            label={t("WINDSCREEN.HEATING")}
          />
          <FormControlLabel
            checked={formik.values.windscreen.camera}
            onChange={() =>
              formik.setFieldValue(
                "windscreen.camera",
                !formik.values.windscreen.camera
              )
            }
            control={<Checkbox />}
            label={t("WINDSCREEN.CAMERA")}
          />
          <FormControlLabel
            checked={formik.values.windscreen.huDisplay}
            onChange={() =>
              formik.setFieldValue(
                "windscreen.huDisplay",
                !formik.values.windscreen.huDisplay
              )
            }
            control={<Checkbox />}
            label={t("WINDSCREEN.HU_DISPLAY")}
          />
        </FormGroup>
      </CardContent>
    </Card>
  );
};

export default WindScreenCard;
