import Grid from "@mui/material/Grid";
import useAxios from "axios-hooks";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import Loading from "../../core/Loading";
import ErrorBox from "../../errors/ErrorBox";
import ErrorSnack from "../../errors/ErrorSnack";
import { createOrderSubmitPayload } from "../../orders/createOrderSubmitPayload";
import {
  ErpError,
  SubmitOrderResponse,
} from "../../orders/SubmitOrderResponse";
import { OrderType } from "../../OrderType";
import { Page } from "../../Page";
import { SettingsType } from "../../settings/SettingsType";
import { trackEvent } from "../../shared/trackEvent";
import Buttons from "../Buttons";
import BookTimeCard from "./BookTimeCard";
import VehicleCard from "./VehicleCard";
import WindScreenCard from "./WindScreenCard";
import WipersCard from "./WipersCard";

type Props = {
  order: OrderType;
  onNext: any;
  onBack: any;
};

const Step2 = ({ order, onBack, onNext }: Props) => {
  useEffect(() => {
    trackEvent("Booking", "Step 2 Opened")
  }, [])

  const { t }: { t: any } = useTranslation();

  const validationSchema = yup.object({
    job: yup.object({
      department: yup.object().nullable().required(t("SERVICE.DEPARTMENT_REQUIRED")),
      jobDate: yup.string().required(t("SERVICE.DATE_REQUIRED")),
      customerStartTime: yup.string().required(t("SERVICE.TIME_REQUIRED")),
    }).nullable(),
    vehicle: yup.object({
      plateNo: yup.string().test(
        "plateNoRequired",
        t("VEHICLE.PLATE_NO_REQUIRED"),
        function (value) {
          if (
            value ||
            (this.parent.attachments && this.parent.attachments.length > 0)
          )
            return true;
          return false;
        }
      ),
      make: yup.object().nullable().test("makeRequired", t("VEHICLE.MAKE_REQUIRED"), function (value) {
        if (
          value ||
          (this.parent.attachments && this.parent.attachments.length > 0)
        )
          return true;
        return false;
      }),
      model: yup.object().nullable(),
      year: yup.string().nullable().test("year", t("VEHICLE.YEAR_REQUIRED"), function (value) {
        if (
          value ||
          (this.parent.attachments && this.parent.attachments.length > 0)
        )
          return true;
        return false;
      }),
      vin: yup.string(),
      attachments: yup.array(),
    }),
  });

  const pageType: "BoL2A" | "BoL2B" | "BoL2C1" | "BoL2C2" = order.insurance
    .kasko
    ? "BoL2A"
    : order.damage.damageType === "chip"
      ? "BoL2C1"
      : "BoL2B";

  const [vehiclesLoading, setVehiclesLoading] = useState(false);

  const handleVehiclesLoading = (isloading: boolean) => {
    setVehiclesLoading(isloading);
  };

  const [bookingLoading, setBookingLoading] = useState(false);

  const handleBookingLoading = (isloading: boolean) => {
    setBookingLoading(isloading);
  };

  const [{ loading: orderSubmitting, error: submitError }, submitOrder] =
    useAxios<SubmitOrderResponse>(
      {
        url: `${process.env.REACT_APP_API_URL}booking`,
        method: "POST",
      },
      {
        useCache: false,
        manual: true,
      }
    );

  const [erpError, setErpError] = useState<ErpError>();

  const formik = useFormik<OrderType>({
    initialValues: order,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setErpError(undefined);

      const updatedOrder = {
        ...order,
        job: { ...formik.values.job },
        vehicle: { ...formik.values.vehicle },
        windscreen: { ...formik.values.windscreen },
        wipers: formik.values.wipers,
        opportunityId: order.opportunityId,
        opportunityNo: order.opportunityNo,
        ip: settings?.ip,
      };

      const originalOrderData = await createOrderSubmitPayload(order, 2);
      const orderData = await createOrderSubmitPayload(updatedOrder, 2);

      const response = await submitOrder({
        data: { ...originalOrderData, ...orderData },
      });

      if (response.data.error) {
        trackEvent("Booking", "Step 2 Server Error")
        setErpError(response.data.error);
      } else {
        trackEvent("Booking", "Step 2 Submitted")
        onNext(updatedOrder);
        window.scrollTo({
          top: 0,
          behavior: "auto",
        });
      }
    },
  });

  const [{ data: settings, loading: settingsLoading }] = useAxios<
    Page<SettingsType>
  >(
    {
      url: `${process.env.REACT_APP_API_URL}settings`,
    },
    { useCache: false }
  );

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleNext = () => {
    trackEvent("Booking", "Clicked Send")
    setSnackbarOpen(true);
    formik.handleSubmit();
  };

  const handleBack = () => {
    trackEvent("Booking", "Clicked Back")
    const data: OrderType = {
      ...order,
      job: { ...formik.values.job },
      vehicle: { ...formik.values.vehicle },
      windscreen: { ...formik.values.windscreen },
      wipers: formik.values.wipers,
      opportunityId: order.opportunityId,
      opportunityNo: order.opportunityNo,
      ip: settings?.ip,
    }

    onBack(data)
  }

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      {(bookingLoading || vehiclesLoading || settingsLoading) && <Loading />}
      <Grid container spacing={2} p={{ xs: 0, md: 2 }}>
        <Grid item xs={12} md={6}>
          {/* <div>{JSON.stringify(formik.values)}</div> */}
          <BookTimeCard
            settings={settings?.data}
            formik={formik}
            onLoading={handleBookingLoading}
            pageType={pageType}
            noOfChips={order.damage?.noOfChips ?? 0}
            jobType={order.damage.damageType === "chip" ? "repair" : "replacement"}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <VehicleCard
            formik={formik}
            doNotAskVin={!(order.insurance?.insuranceCompany?.askVin ?? true)}
            onLoading={handleVehiclesLoading}
          />
        </Grid>

        {pageType === "BoL2B" && (
          <Grid item xs={12}>
            <WindScreenCard formik={formik} />
          </Grid>
        )}

        <Grid item xs={12}>
          <WipersCard formik={formik} />
        </Grid>

        <Grid item xs={12} md={12}>
          <Buttons
            activeStep={2}
            handleBack={handleBack}
            handleNext={handleNext}
            submitting={orderSubmitting}
          />
          <ErrorBox error={submitError} erpError={erpError} />
        </Grid>
      </Grid>
      {!formik.isValid && (
        <ErrorSnack
          hasError={!formik.isValid && snackbarOpen}
          handleClose={() => setSnackbarOpen(false)}
        />
      )}
    </form>
  );
};

export default Step2;
