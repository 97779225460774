import { Autocomplete, Divider } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useAxios from "axios-hooks";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BookingTimesType } from "../../booking-times/BookingTimesType";
import { DepartmentType } from "../../DepartmentType";
import { Page } from "../../Page";
import { SettingsType } from "../../settings/SettingsType";
import TimeSelector from "../../time-selector/TimeSelector";
import PriceCard from "./PriceCard";

type Props = {
  formik: any;
  onLoading: (isLoading: boolean) => void;
  settings?: SettingsType[];
  pageType: "BoL2A" | "BoL2B" | "BoL2C1" | "BoL2C2";
  noOfChips: number;
  jobType: string;
};

const BookTimeCard = ({
  formik,
  onLoading,
  settings,
  pageType,
  noOfChips,
  jobType,
}: Props) => {
  const { t }: { t: any } = useTranslation();

  const [currentDate, setCurrentDate] = useState(new Date());
  const [departmentId, setDepartmentId] = useState<number>();

  const handleCurrentDateChange = (date: Date) => {
    setCurrentDate(date);
  };

  const [{ data: departments, loading: departmentsLoading }] = useAxios<
    Page<DepartmentType>
  >(
    {
      url: `${process.env.REACT_APP_API_URL}departments`,
    },
    { useCache: false }
  );

  const [bookingTimes, setBookingTimes] = useState<BookingTimesType>();

  const [{ loading: bookingTimesLoading }, getBookingTimes] =
    useAxios<BookingTimesType>(
      {
        url: `${process.env.REACT_APP_API_URL}booking`,
      },
      {
        manual: true,
        useCache: false,
      }
    );

  useEffect(() => {
    onLoading(bookingTimesLoading || departmentsLoading);
  }, [bookingTimesLoading, departmentsLoading, onLoading]);

  useEffect(() => {
    if (!departmentId) {
      setBookingTimes(undefined);
      return;
    }

    const get = async () => {
      const bookingTimes = await getBookingTimes({
        params: {
          department_id: departmentId,
          job_type: jobType,
          date: format(currentDate, "yyyy-MM-dd"),
          week: 1,
        },
      });

      setBookingTimes(bookingTimes.data);
    };
    get();
  }, [departmentId, currentDate, getBookingTimes, jobType]);

  const handleDateSelect = (date: string) => {
    formik.setFieldValue("job.customerStartTime", "");
    formik.setFieldValue("job.jobDate", date);
  };

  const handleTimeSelect = (time: string) => {
    formik.setFieldValue("job.customerStartTime", time);
  };

  return (
    <Card
      elevation={0}
      sx={{
        height: "100%",
        "& .css-57jpp3-MuiFormHelperText-root.Mui-error": {
          fontWeight: "normal",
          fontSize: "12px",
        },
      }}
    >
      {pageType === "BoL2C1" &&
        settings &&
        settings.find((o) => o.key === "repair_price.show_repair_price")
          ?.boolValue && (
          <>
            <CardHeader
              title={
                <Typography
                  variant={"h6"}
                  dangerouslySetInnerHTML={{ __html: t("SERVICE.HEADING") }}
                ></Typography>
              }
            />
            <Divider variant="middle" />
            <CardHeader
              title={<PriceCard settings={settings} noOfChips={noOfChips} />}
            />
          </>
        )}
      <CardHeader
        title={
          <Typography variant={"h6"}>
            {t("SERVICE.SELECT_DEPARTMENT")}
          </Typography>
        }
      />
      <Divider variant="middle" />
      <CardContent>
        <FormGroup>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                autoHighlight
                disablePortal
                id="job.department"
                noOptionsText={t("SHARED.NO_OPTIONS")}
                options={departments?.data ?? []}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off",
                    }}
                    label={t("SERVICE.DEPARTMENT")}
                    error={
                      formik.touched.job?.department &&
                      Boolean(formik.errors.job?.department)
                    }
                    helperText={
                      formik.touched.job?.department &&
                      formik.errors.job?.department
                    }
                  />
                )}
                onChange={(e, v) => {
                  formik.setFieldValue("job.jobDate", "");
                  formik.setFieldValue("job.customerStartTime", "");
                  formik.setFieldValue("job.department", v);
                  setDepartmentId(v?.departmentId);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.departmentId === value.departmentId
                }
              />
            </Grid>
          </Grid>
        </FormGroup>
      </CardContent>
      {bookingTimes && bookingTimes.result.length === 0 && (
        <CardHeader
          title={<Typography variant={"h6"}>{t("SERVICE.NO_TIME")}</Typography>}
        />
      )}
      {bookingTimes && bookingTimes.result.length !== 0 && (
        <>
          <CardHeader
            title={
              <Typography variant={"h6"}>{t("SERVICE.SELECT_TIME")}</Typography>
            }
          />
          <Divider variant="middle" />
          <CardContent>
            <FormGroup>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TimeSelector
                    formik={formik}
                    bookingTimes={bookingTimes}
                    onSelectDate={handleDateSelect}
                    onSelectTime={handleTimeSelect}
                    date={formik.values.job?.jobDate}
                    time={formik.values.job?.customerStartTime}
                    currentDate={currentDate}
                    onCurrentDateChange={handleCurrentDateChange}
                  />
                </Grid>
              </Grid>
            </FormGroup>
          </CardContent>
        </>
      )}
    </Card>
  );
};

export default BookTimeCard;
