import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import useAxios from "axios-hooks";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AttachImage from "../../attach-image/AttachImage";
import { MakeType } from "../../MakeType";
import { ModelType } from "../../ModelType";
import { Page } from "../../Page";

type Props = {
  formik: any;
  doNotAskVin?: boolean;
  onLoading: (isLoading: boolean) => void;
};

const VehicleCard = ({ formik, doNotAskVin, onLoading }: Props) => {
  const { t }: { t: any } = useTranslation();

  const [{ data: makes, loading: makesLoading }] = useAxios<Page<MakeType>>(
    {
      url: `${process.env.REACT_APP_API_URL}makes`,
    },
    { useCache: false }
  );

  const [{ data: models, loading: modelsLoading }, getModels] = useAxios<
    Page<ModelType>
  >(
    {
      url: `${process.env.REACT_APP_API_URL}models`,
    },
    {
      manual: true,
      useCache: false,
    }
  );

  useEffect(() => {
    onLoading(makesLoading || modelsLoading);
  }, [makesLoading, modelsLoading, onLoading]);

  const years = Array.from(Array(new Date().getFullYear() - 1949), (_, i) =>
    (i + 1950).toString()
  ).reverse();

  if (new Date().getMonth() >= 10) {
    let nextYear = new Date().getFullYear();
    years.unshift((nextYear + 1).toString());
  }

  return (
    <Card
      elevation={0}
      sx={{
        height: "100%",
        "& .css-57jpp3-MuiFormHelperText-root.Mui-error": {
          fontWeight: "normal",
          fontSize: "12px",
        },
      }}
    >
      <CardHeader
        title={
          <Typography
            variant={"h6"}
            dangerouslySetInnerHTML={{ __html: t("VEHICLE.VEHICLE") }}
          ></Typography>
        }
      />
      <Divider variant="middle" />
      <CardContent>
        <FormGroup>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
              <AttachImage
                formik={formik}
                field={"vehicle.attachments"}
                message={t("VEHICLE.ATTACH_REGISTRATION_PHOTOS")}
                coverDropzone={false}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Grid item xs={12} md={12}>
                <TextField
                  style={{ marginBottom: "15px" }}
                  fullWidth
                  autoComplete="off"
                  required
                  id="vehicle.plateNo"
                  name="vehicle.plateNo"
                  label={t("VEHICLE.PLATE_NO")}
                  value={formik.values.vehicle.plateNo?.replaceAll(" ", "")?.toUpperCase()}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.vehicle?.plateNo &&
                    Boolean(formik.errors.vehicle?.plateNo)
                  }
                  helperText={
                    formik.touched.vehicle?.plateNo &&
                    formik.errors.vehicle?.plateNo
                  }
                  InputProps={{
                    style: { textTransform: "uppercase" },
                    endAdornment: (
                      <Tooltip title={t("VEHICLE.PLATE_NO_NOTE")}>
                        <InfoOutlinedIcon sx={{ fill: "grey" }} />
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Stack spacing={2}>
                  <Autocomplete
                    fullWidth
                    autoHighlight
                    disablePortal
                    id="vehicle.make"
                    noOptionsText={t("SHARED.NO_OPTIONS")}
                    options={makes?.data ?? []}
                    getOptionLabel={(option) => option.make}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "off",
                        }}
                        label={t("VEHICLE.MAKE")}
                        error={
                          formik.touched.vehicle?.make &&
                          Boolean(formik.errors.vehicle?.make)
                        }
                        helperText={
                          formik.touched.vehicle?.make &&
                          formik.errors.vehicle?.make
                        }
                      />
                    )}
                    onChange={async (e, v) => {
                      formik.setFieldValue("vehicle.model", null);
                      formik.setFieldValue("vehicle.make", v);
                      if (v) {
                        await getModels({
                          params: {
                            make_id: v.makeId,
                          },
                        });
                      }
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    value={formik.values.vehicle.make}
                  />
                  <Autocomplete
                    style={{ marginBottom: "15px" }}
                    fullWidth
                    autoHighlight
                    disablePortal
                    id="vehicle.model"
                    noOptionsText={t("SHARED.NO_OPTIONS")}
                    options={models?.data ?? []}
                    getOptionLabel={(option) => option.model}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "off",
                        }}
                        label={t("VEHICLE.MODEL")}
                        error={
                          formik.touched.vehicle?.model &&
                          Boolean(formik.errors.vehicle?.model)
                        }
                        helperText={
                          formik.touched.vehicle?.model &&
                          formik.errors.vehicle?.model
                        }
                      />
                    )}
                    onChange={(e, v) =>
                      formik.setFieldValue("vehicle.model", v)
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    value={formik.values.vehicle.model}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={12}>
                <Autocomplete
                  style={{ marginBottom: "15px" }}
                  autoHighlight
                  disablePortal
                  id="vehicle.year"
                  noOptionsText={t("SHARED.NO_OPTIONS")}
                  options={years ?? []}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "off",
                      }}
                      label={t("VEHICLE.YEAR")}
                      error={
                        formik.touched.vehicle?.year &&
                        Boolean(formik.errors.vehicle?.year)
                      }
                      helperText={
                        formik.touched.vehicle?.year &&
                        formik.errors.vehicle?.year
                      }
                    />
                  )}
                  onChange={(e, v) => formik.setFieldValue("vehicle.year", v)}
                  isOptionEqualToValue={(option, value) => option === value}
                  value={formik.values.vehicle.year}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                {!doNotAskVin && (
                  <TextField
                    fullWidth
                    id="vehicle.vin"
                    name="vehicle.vin"
                    label={t("VEHICLE.VIN")}
                    value={formik.values.vehicle.vin.replaceAll(" ", "")}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.vehicle?.vin &&
                      Boolean(formik.errors.vehicle?.vin)
                    }
                    helperText={
                      formik.touched.vehicle?.vin && formik.errors.vehicle?.vin
                    }
                    InputProps={{
                      endAdornment: (
                        <Tooltip title={t("VEHICLE.VIN_NOTE")}>
                          <InfoOutlinedIcon sx={{ fill: "grey" }} />
                        </Tooltip>
                      ),
                    }}
                  />
                )}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <FormControl
                error={
                  formik.touched.vehicle?.owner &&
                  Boolean(formik.errors.vehicle?.owner)
                }
              >
                <RadioGroup
                  aria-label="Owner"
                  name="owner"
                  value={formik.values.vehicle?.owner?.ownerType}
                  defaultValue="naturalPerson"
                  onChange={(e) =>
                    formik.setFieldValue(
                      "vehicle.owner.ownerType",
                      e.target.value
                    )
                  }
                >
                  <FormLabel>{t("VEHICLE.OWNER")}</FormLabel>
                  <FormHelperText>
                    {formik.touched.vehicle?.owner?.ownerType &&
                      formik.errors.vehicle?.owner?.ownerType}
                  </FormHelperText>
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={0}>
                    <FormControlLabel
                      value="naturalPerson"
                      control={<Radio />}
                      label={t("VEHICLE.NATURAL_PERSON")}
                    />
                    <FormControlLabel
                      value="legalPerson"
                      control={<Radio />}
                      label={t("VEHICLE.LEGAL_PERSON")}
                    />
                  </Stack>
                </RadioGroup>
              </FormControl>
              {formik.values.vehicle.owner.ownerType === "legalPerson" && (
                <div>
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      id="vehicle.owner.code"
                      name="vehicle.owner.code"
                      label={t("VEHICLE.OWNER_CODE")}
                      value={formik.values.vehicle?.owner?.code}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.vehicle?.code &&
                        Boolean(formik.errors.vehicle?.owner?.code)
                      }
                      helperText={
                        formik.touched.vehicle?.owner?.code &&
                        formik.errors.vehicle?.owner?.code
                      }
                    />
                    <TextField
                      fullWidth
                      autoComplete="off"
                      id="vehicle.owner.name"
                      name="vehicle.owner.name"
                      label={t("VEHICLE.OWNER_NAME")}
                      value={formik.values.vehicle?.owner?.name}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.vehicle?.name &&
                        Boolean(formik.errors.vehicle?.owner?.name)
                      }
                      helperText={
                        formik.touched.vehicle?.owner?.name &&
                        formik.errors.vehicle?.owner?.name
                      }
                    />
                  </Stack>
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                id="vehicle.important"
                name="vehicle.important"
                label={t("VEHICLE.IMPORTANT")}
                value={formik.values.vehicle.important}
                onChange={formik.handleChange}
                error={
                  formik.touched.vehicle?.important &&
                  Boolean(formik.errors.vehicle?.important)
                }
                helperText={
                  formik.touched.vehicle?.important &&
                  formik.errors.vehicle?.important
                }
              />
            </Grid>
          </Grid>
        </FormGroup>
      </CardContent>
    </Card>
  );
};

export default VehicleCard;
